<template>
  <svg
    class="cursor-pointer"
    :class="{ 'text-bb-disabled-gray': disabled, 'text-bb-disabled-gray hover:text-bb-text-secondary': !disabled }"
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @click="close"
  >
    <path
      d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IcCross',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      if (!this.disabled) this.$emit('close')
    },
  },
}
</script>

<style scoped></style>
