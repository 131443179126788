<template>
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="16.5"
      cy="16"
      r="16"
      fill="#F4F2FF"
    />
    <path
      d="M7.5 17H9.5V15H7.5V17ZM7.5 21H9.5V19H7.5V21ZM7.5 13H9.5V11H7.5V13ZM11.5 17H25.5V15H11.5V17ZM11.5 21H25.5V19H11.5V21ZM11.5 11V13H25.5V11H11.5ZM7.5 17H9.5V15H7.5V17ZM7.5 21H9.5V19H7.5V21ZM7.5 13H9.5V11H7.5V13ZM11.5 17H25.5V15H11.5V17ZM11.5 21H25.5V19H11.5V21ZM11.5 11V13H25.5V11H11.5Z"
      fill="#6366FA"
    />
  </svg>
</template>
<script>
export default {
  name: 'IcList',
}
</script>
