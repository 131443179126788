<template>
  <div class="md:mx-6 mt-4 mb-10">
    <div>
      <KeywordSuggestionsCard :suggestions="suggestions"></KeywordSuggestionsCard>
    </div>
  </div>
</template>

<script>
import KeywordSuggestionsCard from '@/views/ai_activity_log/cards/KeywordSuggestionsCard'
export default {
  name: 'AiKeywordSuggestionsTab',
  components: {
    KeywordSuggestionsCard,
  },
  props: {
    suggestions: {
      type: Object,
      required: true,
    },
  },
}
</script>
