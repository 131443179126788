<template>
  <svg
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 8.5L2.75 5.75L0 4.5L2.75 3.25L4 0.5L5.25 3.25L8 4.5L5.25 5.75L4 8.5Z"
      fill="#6366FA"
    />
    <path
      d="M4 14.5L5.25 17.25L8 18.5L5.25 19.75L4 22.5L2.75 19.75L0 18.5L2.75 17.25L4 14.5Z"
      fill="#6366FA"
    />
    <path
      d="M11.5 9L14 3.5L16.5 9L22 11.5L16.5 14L14 19.5L11.5 14L6 11.5L11.5 9ZM13.01 12.49L14 14.67L14.99 12.49L17.17 11.5L14.99 10.51L14 8.33L13.01 10.51L10.83 11.5L13.01 12.49Z"
      fill="#6366FA"
    />
  </svg>
</template>

<script>
export default {
  name: 'IcAiActivity',
}
</script>

<style scoped></style>
