<template>
  <div
    class="rounded-full w-fit"
    :class="chipTagStyling.background"
  >
    <p
      class="capitalize text-xs px-3 py-0.5"
      :class="chipTagStyling.text"
    >
      {{ label }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'ChipTag',
  props: {
    label: {
      type: String,
      required: true,
    },
  },
  computed: {
    chipTagStyling() {
      switch (this.label) {
        case 'accept':
          return { text: 'text-bb-green-accepted', background: 'bg-bb-green-50' }
        case 'rejected':
          return { text: 'text-bb-error', background: 'bg-bb-red-50' }
        case 'reverted':
          return { text: 'text-bb-warning-200', background: 'bg-bb-warning-100' }
        default:
          return { text: 'text-neutral-100', background: 'bg-neutral-50' }
      }
    },
  },
}
</script>

<style scoped></style>
