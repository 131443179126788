<template>
  <div class="px-4 w-full actionCard justify-around items-center gap-2 grid md:grid-cols-11">
    <div class="px-3 py-6 flex-col col-span-3 justify-center items-start gap-1 inline-flex">
      <div class="text-gray-500 text-xs leading-tight">Suggestion</div>
      <div class="text-gray-700 text-sm font-bold">{{ action.keyword }}</div>
    </div>
    <div class="px-3 py-6 col-span-2 flex-col justify-center items-start gap-1 inline-flex">
      <div class="text-gray-500 text-xs leading-tight">Date</div>
      <div class="text-gray-700 text-sm font-normal">{{ action.created_at }}</div>
    </div>
    <div class="px-3 py-6 flex-col justify-center items-start gap-1 inline-flex">
      <div class="text-gray-500 text-xs leading-tight">Match type</div>
      <div class="text-gray-700 text-sm font-normal lowercase first-letter-uppercase">{{ action.match_type }}</div>
    </div>
    <div class="px-3 py-6 bg-white flex-col justify-center items-start gap-1 inline-flex">
      <div class="text-gray-500 text-xs leading-tight">Add as</div>
      <div class="justify-end items-center gap-2 inline-flex">
        <status :status="action.label.toLowerCase()" />
      </div>
    </div>
    <div class="px-3 py-6 flex-col justify-center items-start col-span-2 gap-1 inline-flex">
      <div class="text-gray-500 text-xs leading-tight">Ad Group</div>
      <div class="text-gray-700 text-sm font-normal">{{ action.ad_group_name }}</div>
    </div>
    <div class="px-3 py-6 flex-col justify-center items-start gap-1 inline-flex">
      <div class="text-gray-500 text-xs leading-tight">User</div>
      <div class="text-gray-700 text-sm font-normal first-letter-uppercase">{{ action.user }}</div>
    </div>
    <div class="px-3 py-6 flex-col justify-center items-center gap-1 inline-flex">
      <div class="text-gray-500 text-xs leading-tight">Action Taken</div>
      <chip-tag :label="action.action_taken" />
    </div>
  </div>
</template>

<script>
import ChipTag from '@/components/shared/ChipTag'
import Status from '@/components/shared/Status'

export default {
  name: 'ActionCard',
  components: {
    ChipTag,
    Status,
  },
  props: {
    action: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>
.actionCard {
  background: #ffffff;
  box-shadow: 0px 7px 12px 0px #8898aa26;
  border-radius: 8px;
  border: 0px, 0px, 1px, 0px;
}
.first-letter-uppercase::first-letter {
  text-transform: uppercase;
}
</style>