<template>
  <page>
    <page-section
      full-width
      bottom-border
    >
      <page-title>
        <div class="flex items-center">
          <div class="mr-3">
            <IcAiActivity />
          </div>
          <div>AI Activity Log</div>
        </div>
      </page-title>
      <div class="md:flex">
        <div class="flex items-center gap-3">
          <div class="text-sm text-bb-text-default">Select Campaign</div>
          <search-input
            class="w-300"
            input-id="search-campaign"
            input-name="search-campaign"
            placeholder="Search Campaign..."
            :options="campaigns"
            :disabled="campaigns.length === 0"
            options-suffix="autopilot"
            @select-item="selectCampaign"
          />
        </div>
        <div class="flex items-center gap-3 md:ml-5 xs:mt-3 md:mt-0">
          <div class="text-sm text-bb-text-default">Date Range</div>
          <date-picker
            v-model="datePicker"
            :clearable="false"
            range
            class="mt-2"
            :shortcuts="shortcuts"
            @input="dateRangeChanged"
          ></date-picker>
        </div>
      </div>
    </page-section>

    <!--MAIN CONTAINER-->
    <div class="main-container base-scrollbar bg-white">
      <div class="px-4 pb-4 h-full mt-4">
        <div class="my-4">
          <h4>AI Keyword Automation</h4>
        </div>
        <page-tabs
          :selected-tab="selectedTab"
          :tabs="tabs"
          @select-tab="
            tab => {
              selectedTab = tab
            }
          "
        />

        <div
          v-if="!selectedCampaign || !selectedCampaign.value || !dateRange?.length"
          class="flex justify-center items-center h-full"
        >
          <div class="text-center space-y-2 w-120">
            <p class="text-md font-medium">Select a campaign and a date range to continue</p>
            <p class="text-sm">
              Select a campaign from the dropdown located in the top left corner and a date range to get the AI activity
              log.
            </p>
          </div>
        </div>
        <div v-else-if="isLoading && selectedCampaign.value">
          <brightbid-loader
            class="mt-40"
            size="100"
          />
        </div>

        <div v-else>
          <component
            :is="selectedTab"
            :autopilot="autopilot"
            :suggestions="suggestions"
          />
        </div>
      </div>
    </div>
  </page>
</template>

<script>
import Page from '@/components/base/page/Page.vue'
import PageSection from '@/components/base/page/PageSection.vue'
import SearchInput from '@/components/input/brightbid/SearchInput'
import AutopilotTab from '@/views/ai_activity_log/AutopilotTab'
import AiKeywordSuggestionsTab from '@/views/ai_activity_log/AiKeywordSuggestionsTab'
import PageTabs from '@/components/shared/PageTabs'
import MergeButtonRound from '@/components/btn/MergeButtonRound'
import BrightbidLoader from '@/components/loader/BrightbidLoader.vue'
import IcCog from 'vue-material-design-icons/Cog.vue'
import Toast from '@/components/shared/Toast.vue'
import BbBaseModal from '@/components/modals/brightbid/BbBaseModal.vue'
import CheckBox from '@/components/input/brightbid/CheckBox.vue'
import { mapGetters, mapState } from 'vuex'
import IcAiActivity from '@/components/icon/ic-ai-activity.vue'
import DatePicker from 'vue2-datepicker'

export default {
  name: 'AiActivityLogViewView',
  components: {
    CheckBox,
    BbBaseModal,
    BrightbidLoader,
    IcCog,
    MergeButtonRound,
    Page,
    PageSection,
    PageTabs,
    SearchInput,
    AutopilotTab,
    AiKeywordSuggestionsTab,
    DatePicker,
    IcAiActivity,
  },
  data() {
    return {
      isLoading: false,
      tabs: [
        { label: 'Autopilot', value: 'autopilot-tab' },
        {
          label: 'Keyword Suggestions',
          value: 'ai-keyword-suggestions-tab',
        },
      ],
      selectedTab: 'autopilot-tab',
      selectedCampaign: null,
      campaigns: [],
      autopilot: {
        summary: {
          total: 0,
          positive: 0,
          negative: 0,
        },
        actions: [],
        autopilotStatus: false,
      },
      suggestions: {
        summary: {
          total: 0,
          positive: 0,
          negative: 0,
          pause: 0,
        },
        keywordStatus: false,
      },
      dateRange: null,
      datePicker: null,
      siteId: null,
      shortcuts: [
        {
          text: 'Last 7 Days',
          onClick: () => {
            const today = new Date()
            const last7Days = new Date()
            last7Days.setDate(today.getDate() - 6)
            return [last7Days, today]
          },
        },
        {
          text: 'Last 14 Days',
          onClick: () => {
            const today = new Date()
            const last14Days = new Date()
            last14Days.setDate(today.getDate() - 13)
            return [last14Days, today]
          },
        },
        {
          text: 'Last 30 Days',
          onClick: () => {
            const today = new Date()
            const last30Days = new Date()
            last30Days.setDate(today.getDate() - 29)
            return [last30Days, today]
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    ...mapState('site', ['selectedSite']),
  },
  watch: {
    async selectedSite(newSite) {
      if (newSite.value !== this.siteId) {
        this.campaigns = []
        this.selectedCampaign = null
        // get the new site campaigns
        await this.getCustomerCampaigns()
      }
    },
  },
  mounted() {
    const today = new Date()
    const last7Days = new Date()
    last7Days.setDate(today.getDate() - 6)
    this.datePicker = [last7Days, today]
    this.dateRange = this.datePicker.map(date => new Date(date).toISOString().slice(0, 10))
  },
  async created() {
    if (!this.selectedSite) return
    await this.getCustomerCampaigns()
  },
  methods: {
    async getAutoPilotData() {
      if (!this.selectedCampaign || !this.dateRange?.length) return
      let url = `/search/site/${this.siteId}/ai-log/autopilot?start_date=${this.dateRange[0]}&end_date=${this.dateRange[1]}`
      let allCampaignsSelected = false
      if (this.selectedCampaign.value !== 'all') {
        url += `&google_campaign_id=${this.selectedCampaign.value}`
      } else {
        allCampaignsSelected = true
      }
      const { data } = await this.$http.get(url)
      this.autopilot = {
        summary: data.summary,
        actions: data.data ?? [],
        autopilotStatus: data.autopilot_enabled,
        allCampaignsSelected: allCampaignsSelected,
      }
    },
    async getCustomerCampaigns() {
      this.siteId = this.selectedSite.value
      try {
        const { data: rawCampaigns } = await this.$http.get(`/search/site/${this.siteId}/campaigns`)

        if (rawCampaigns.length === 0) {
          this.$toast.error({
            component: Toast,
            props: {
              title: 'Error',
              message: `This site has no eligible search campaigns that can receive keyword suggestions. Please choose a site that has eligible search campaigns.`,
              type: 'error',
            },
          })
          return
        }

        this.campaigns = rawCampaigns.map(campaign => ({
          label: campaign.name,
          value: campaign.campaign_id,
          suffix: campaign.autopilot,
        }))

        this.campaigns.unshift({
          label: 'All Campaigns',
          value: 'all',
        })
      } catch (e) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: `This site does not have a connected Google Ads account. Please connect a Google Ads account to this site to receive keyword suggestions.`,
            type: 'error',
          },
        })
      }
    },
    async selectCampaign(campaign) {
      if (campaign?.value === null || campaign.value === this.selectedCampaign?.value) return

      this.selectedCampaign = campaign
      if (!this.dateRange?.length) return
      this.isLoading = true
      await Promise.all([this.getAutoPilotData(), this.getKeywordSuggestionsData()])
      this.isLoading = false
    },
    async getKeywordSuggestionsData() {
      if (!this.selectedCampaign || !this.dateRange?.length) return
      let url = `/search/site/${this.siteId}/ai-log/keyword-suggestions?start_date=${this.dateRange[0]}&end_date=${this.dateRange[1]}`
      let allCampaignsSelected = false
      if (this.selectedCampaign.value !== 'all') {
        url += `&google_campaign_id=${this.selectedCampaign.value}`
      } else {
        allCampaignsSelected = true
      }
      const { data } = await this.$http.get(url)
      this.suggestions = {
        summary: data.summary,
        keywordStatus: data.keyword_suggestions_enabled,
        allCampaignsSelected: allCampaignsSelected,
      }
    },
    async dateRangeChanged(dateRange) {
      if (dateRange.some(date => !date)) return
      this.dateRange = dateRange.map(date => new Date(date).toISOString().slice(0, 10))
      this.isLoading = true
      await Promise.all([this.getAutoPilotData(), this.getKeywordSuggestionsData()])
      this.isLoading = false
    },
  },
}
</script>

<style lang="scss">
// $default-color: #f4f2ff;
$primary-color: $bb-brand-purple;
@import 'node_modules/vue2-datepicker/scss/index.scss';

.mx-input {
  border-radius: 8px;
  height: 40px;
  border: #e5e7eb solid 1px;
  padding: 8px 30px;
  font-size: 14px;
}

.main-container {
  overflow-y: auto;
  height: calc(100vh - 222px);
}
</style>
