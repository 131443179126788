<template>
  <div class="md:mx-6 mt-4 mb-10">
    <div>
      <AutopilotCard :autopilot="autopilot"></AutopilotCard>
    </div>
    <div v-if="autopilot">
      <div class="text-gray-700 text-sm leading-snug my-3">All Actions</div>
      <ActionCard
        v-for="action in autopilot.actions"
        :key="action.keyword"
        :action="action"
        class="mb-4"
      ></ActionCard>
    </div>
  </div>
</template>

<script>
import AutopilotCard from '@/views/ai_activity_log/cards/AutopilotCard'
import ActionCard from '@/views/ai_activity_log/cards/ActionCard.vue'
export default {
  name: 'AutopilotTab',
  components: {
    AutopilotCard,
    ActionCard,
  },
  props: {
    autopilot: {
      type: Object,
      required: true,
    },
  },
}
</script>
